import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { NextSeo } from 'next-seo';
import Link from 'next/link';

export function Header() {
  const router = useRouter();
  const isBondFinder = router.pathname.includes('find');
  const isStateMapPage = router.pathname.includes('states');
  const isProductPage = router.pathname === '/[stateName]/[bondName]';
  return (
    <>
      <HeaderTopBar />
      {!isBondFinder && !isStateMapPage && (
        <HeaderBottomBar isProductPage={isProductPage} />
      )}
    </>
  );
}

export function HeaderTopBar() {
  return (
    <div className="w-full bg-blue-surety mx-auto">
      <div className="max-w-screen-lg px-4 mx-auto w-full flex justify-center sm:justify-between items-center">
        <img
          className="w-36 block mr-5 sm:mr-0 sm:w-48 h-full py-3"
          src="/surety-logo-white.svg"
          alt=""
        />
        <div className="text-white  text-xs sm:text-sm font-light flex flex-col items-end">
          <div className="block">
            <span className="hidden md:inline">We're powered by</span>
            <span>
              {' '}
              the{' '}
              <span className="font-semibold">
                #1 Online Surety Bond Agency
              </span>{' '}
            </span>
            in the Nation!
          </div>
          <div className="hidden sm:flex items-center space-x-1">
            <a href="tel:+18003084358" className="text-white">
              1 (800) 308-4358
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export function HeaderBottomBar({ isProductPage }) {
  return (
    <div className="w-full mx-auto border-b">
      <div
        className={classNames(
          'max-w-screen-lg py-3 px-4 mx-auto w-full flex items-center',
          isProductPage
            ? 'justify-center sm:justify-between'
            : 'justify-between'
        )}
      >
        <Link href="/" legacyBehavior>
          <div className="flex cursor-pointer items-center space-x-1 sm:space-x-2">
            <svg
              className="sm:h-10 h-8 text-blue-surety fill-current"
              viewBox="0 0 36 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21.0451 34.7162C20.999 34.7162 20.9529 34.7162 20.9068 34.7162C13.0468 34.7162 6.66197 28.341 6.66197 20.4928C6.66197 12.6447 13.0468 6.26946 20.9068 6.26946C20.9529 6.26946 20.9529 6.26946 20.999 6.26946V1.98865C20.9529 1.98865 20.9529 1.98865 20.9068 1.98865C10.7187 1.98865 2.44385 10.2511 2.44385 20.4238C2.44385 30.5965 10.7187 38.8589 20.9068 38.8589C20.9529 38.8589 20.999 38.8589 21.0451 38.8589V34.7162Z" />
              <path d="M21.0212 10.4348C15.5123 10.4348 11.0176 14.8998 11.0176 20.3774C11.0176 25.855 15.4893 30.3199 20.9751 30.3199L21.0212 10.4348Z" />
              <path d="M21.0215 6.26941V10.4351C26.5074 10.4351 30.9329 14.8771 30.9329 20.3777C30.9329 25.8323 26.5074 30.2742 21.0445 30.3202V34.7391C28.9276 34.7391 35.2202 28.3869 35.2202 20.5158C35.2202 12.6446 28.9045 6.26941 21.0215 6.26941Z" />
              <path d="M32.1316 18.2827H19.7769V22.6096H32.1316V18.2827Z" />
              <path d="M0 27.1672L3.89543 34.2328L9.24299 32.2765L4.49472 23.5308L0 27.1672Z" />
              <path d="M8.92037 9.07767L4.3104 18.0306L0.20752 14.072L3.6189 7.7428L8.92037 9.07767Z" />
              <path d="M13.1617 40.4927H21.0448V35.1301H12.4702L13.1617 40.4927Z" />
              <path d="M12.4702 5.73996H21.0217V0.676636H13.1848L12.4702 5.73996Z" />
            </svg>
            <div className="md:text-xl font-semibold font-brand text-black">
              ContractorBonds<small>.com</small>
            </div>
          </div>
        </Link>
        {!isProductPage && (
          <ul className="flex flex-row font-medium items-center space-x-5 md:space-x-10 font-ui text-gray-500">
            <li className="hidden text-base sm:text-lg sm:block hover:text-gray-800">
              <Link href="/states">States</Link>
            </li>
            <li>
              <Link
                href="/find"
                className="text-white text-sm sm:text-base hover:opacity-50 transition-all bg-blue-surety px-4 py-2 rounded-full"
              >
                Get Started
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
