import React from 'react';
import { useRouter } from 'next/router';
import { StateOptions } from 'data/static-data/StateOptions';
import classNames from 'classnames';
import Link from 'next/link';

export function Footer() {
  const router = useRouter();
  const isBondFinder = router.pathname.includes('find');
  const isStatePage = router.pathname === '/[stateName]';
  const year = new Date().getFullYear();
  return (
    <footer
      className={classNames(
        'w-full bg-blue-surety px-4',
        isStatePage && 'mt-36'
      )}
    >
      <div className="flex flex-col-reverse md:flex-row  max-w-screen-lg w-full mx-auto  text-white">
        <CopyrightInfo currentYear={year} />
        {!isBondFinder && <FindBondByState />}
      </div>
    </footer>
  );
}

function CopyrightInfo({ currentYear }) {
  return (
    <div className="text-sm flex flex-col w-9/12">
      <div className=" py-5 font-extralight lg:max-w-sm md:max-w-xs max-w-full w-full">
        <h5 className="font-normal py-5">
          Copyright &copy; 1996 - {currentYear} ContractorBonds.com All rights
          reserved.
        </h5>
        <p className="py-1">
          Operated by Brooklyn Ventures LLC, DBA SuretyBonds.com an insurance
          agency
        </p>
        <p className="py-1">
          (Brooklyn Ventures SuretyBonds.com insurance Agency LLC in California)
        </p>
        <p className="py-1">(Brooklyn Ventures of Missouri, LLC in New York)</p>
        <div className=" mx-auto space-x-1 py-6">
          <Link href="/privacy-policy" className="underline font-medium">
             Privacy Policy 
          </Link>
          <span> | </span>
          <Link href="/terms-of-use" className="underline font-medium">
             Terms of Use 
          </Link>
        </div>
      </div>
    </div>
  );
}

function FindBondByState() {
  return (
    <div className="max-w-screen-xl w-full py-6 flex">
      <div className="divide-y divide-light-blue-400 ">
        <h4 className="font-medium py-3">Find a Bond by State</h4>
        <div className="flex flex-wrap gap-x-5 gap-y-1 font-extralight max-w-screen-sm pt-2 pb-5 text-gray-200">
          {StateOptions.map(({ value, stateAsPath }) => (
            <Link
              key={stateAsPath}
              href={`/${stateAsPath}`}
              className="hover:underline hover:text-white ">
              {value}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
