import '../styles/globals.css';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { DefaultLayout } from '../common/components/Layout/Layout';
import { defaultSEOConfig } from '../common/components/Seo';
import Script from 'next/script';
import { GA_TRACKING_ID } from '@utils/gtm';

function MyApp({ Component, pageProps }) {
  const Layout = Component.Layout ? Component.Layout : DefaultLayout;
  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GA_TRACKING_ID}');`,
        }}
      />
      <Script
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="afterInteractive"
      />
      <DefaultSeo {...defaultSEOConfig}></DefaultSeo>
      <Head></Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default MyApp;
