import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import classNames from 'classnames';
import { NextSeo } from 'next-seo';
import { Header } from '@components/Layout/Header';
import { Footer } from '@components/Layout/Footer';
import { ChevronLeftIcon } from '@heroicons/react/outline';

export function DefaultLayout({ children }) {
  return (
    <>
      <div className="relative min-h-screen h-full flex flex-col bg-white overflow-y-hidden w-full">
        <Header></Header>
        <main className="flex-1">{children}</main>
        <Footer></Footer>
      </div>
    </>
  );
}

export function BondFinderLayout({ children }) {
  const router = useRouter();
  const isResultsPage = router.pathname.includes('results');
  return (
    <DefaultLayout>
      <>
        <NextSeo nofollow={true} noindex={true}></NextSeo>
        <div className="w-full max-w-screen-md md:max-w-screen-lg mx-auto py-12 md:py-16 pl-4 md:pl-24">
          <button
            className="inline-flex px-3 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-lg text-gray-600 bg-gray-50 hover:bg-gray-100"
            onClick={() => router.back()}
          >
            <ChevronLeftIcon className="h-4 w-3 text-gray-600 mr-0.5" />
            Back
          </button>
        </div>
        <div
          style={{ minHeight: 'calc(100vh - 47px)' }}
          className="w-full mx-auto mt-10 mb-24 sm:mt-20 h-full flex flex-col items-center"
        >
          <div className="flex w-full max-w-sm flex-col items-center justify-items-center">
            <div className="sm:h-12 h-8 ">
              <svg
                className="text-blue-surety h-full fill-current"
                viewBox="0 0 36 41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21.0451 34.7162C20.999 34.7162 20.9529 34.7162 20.9068 34.7162C13.0468 34.7162 6.66197 28.341 6.66197 20.4928C6.66197 12.6447 13.0468 6.26946 20.9068 6.26946C20.9529 6.26946 20.9529 6.26946 20.999 6.26946V1.98865C20.9529 1.98865 20.9529 1.98865 20.9068 1.98865C10.7187 1.98865 2.44385 10.2511 2.44385 20.4238C2.44385 30.5965 10.7187 38.8589 20.9068 38.8589C20.9529 38.8589 20.999 38.8589 21.0451 38.8589V34.7162Z" />
                <path d="M21.0212 10.4348C15.5123 10.4348 11.0176 14.8998 11.0176 20.3774C11.0176 25.855 15.4893 30.3199 20.9751 30.3199L21.0212 10.4348Z" />
                <path d="M21.0215 6.26941V10.4351C26.5074 10.4351 30.9329 14.8771 30.9329 20.3777C30.9329 25.8323 26.5074 30.2742 21.0445 30.3202V34.7391C28.9276 34.7391 35.2202 28.3869 35.2202 20.5158C35.2202 12.6446 28.9045 6.26941 21.0215 6.26941Z" />
                <path d="M32.1316 18.2827H19.7769V22.6096H32.1316V18.2827Z" />
                <path d="M0 27.1672L3.89543 34.2328L9.24299 32.2765L4.49472 23.5308L0 27.1672Z" />
                <path d="M8.92037 9.07767L4.3104 18.0306L0.20752 14.072L3.6189 7.7428L8.92037 9.07767Z" />
                <path d="M13.1617 40.4927H21.0448V35.1301H12.4702L13.1617 40.4927Z" />
                <path d="M12.4702 5.73996H21.0217V0.676636H13.1848L12.4702 5.73996Z" />
              </svg>
            </div>

            <div className="text-sm sm:text-base font-brand sm:font-semibold font-normal mt-1">
              ContractorBonds<small>.com</small>
            </div>
            <div className="text-lg sm:text-xl md:text-3xl font-brand font-semibold -mt-1">
              BondFinder
            </div>
            <hr className="w-full mb-5 mt-3 max-md" />
          </div>
          <div
            className={classNames(
              'w-full px-4',
              isResultsPage ? 'max-w-screen-lg' : 'max-w-sm'
            )}
          >
            {children}
          </div>
        </div>
      </>
    </DefaultLayout>
  );
}
