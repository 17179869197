var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"xs8EEqSQX08qWhdVsyDZb"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { version } from './package.json';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const prodMode = process.env.NODE_ENV === 'production';
const isLocal = process.env.NEXT_PUBLIC_TARGET === 'local';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: prodMode ? 'production' : 'development',
  release: `contractorbonds@${version}`,
  debug: !!isLocal,
  beforeSend(event) {
    console.log('EVENT CLIENT');
    // dont send error if target env is local
    if (isLocal) {
      return null;
    }
    return event;
  },
});
