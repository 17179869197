export const StateOptions = [
  { value: 'AL', display: 'Alabama', stateAsPath: 'alabama' },
  { value: 'AK', display: 'Alaska', stateAsPath: 'alaska' },
  { value: 'AZ', display: 'Arizona', stateAsPath: 'arizona' },
  { value: 'AR', display: 'Arkansas', stateAsPath: 'arkansas' },
  { value: 'CA', display: 'California', stateAsPath: 'california' },
  { value: 'CO', display: 'Colorado', stateAsPath: 'colorado' },
  { value: 'CT', display: 'Connecticut', stateAsPath: 'connecticut' },
  { value: 'DE', display: 'Delaware', stateAsPath: 'delaware' },
  {
    value: 'DC',
    display: 'District Of Columbia',
    stateAsPath: 'district-of-columbia',
  },
  { value: 'FL', display: 'Florida', stateAsPath: 'florida' },
  { value: 'GA', display: 'Georgia', stateAsPath: 'georgia' },
  { value: 'HI', display: 'Hawaii', stateAsPath: 'hawaii' },
  { value: 'ID', display: 'Idaho', stateAsPath: 'idaho' },
  { value: 'IL', display: 'Illinois', stateAsPath: 'illinois' },
  { value: 'IN', display: 'Indiana', stateAsPath: 'indiana' },
  { value: 'IA', display: 'Iowa', stateAsPath: 'iowa' },
  { value: 'KS', display: 'Kansas', stateAsPath: 'kansas' },
  { value: 'KY', display: 'Kentucky', stateAsPath: 'kentucky' },
  { value: 'LA', display: 'Louisiana', stateAsPath: 'louisiana' },
  { value: 'ME', display: 'Maine', stateAsPath: 'maine' },
  { value: 'MD', display: 'Maryland', stateAsPath: 'maryland' },
  { value: 'MA', display: 'Massachusetts', stateAsPath: 'massachusetts' },
  { value: 'MI', display: 'Michigan', stateAsPath: 'michigan' },
  { value: 'MN', display: 'Minnesota', stateAsPath: 'minnesota' },
  { value: 'MS', display: 'Mississippi', stateAsPath: 'mississippi' },
  { value: 'MO', display: 'Missouri', stateAsPath: 'missouri' },
  { value: 'MT', display: 'Montana', stateAsPath: 'montana' },
  { value: 'NE', display: 'Nebraska', stateAsPath: 'nebraska' },
  { value: 'NV', display: 'Nevada', stateAsPath: 'nevada' },
  { value: 'NH', display: 'New Hampshire', stateAsPath: 'new-hampshire' },
  { value: 'NJ', display: 'New Jersey', stateAsPath: 'new-jersey' },
  { value: 'NM', display: 'New Mexico', stateAsPath: 'new-mexico' },
  { value: 'NY', display: 'New York', stateAsPath: 'new-york' },
  { value: 'NC', display: 'North Carolina', stateAsPath: 'north-carolina' },
  { value: 'ND', display: 'North Dakota', stateAsPath: 'north-dakota' },
  { value: 'OH', display: 'Ohio', stateAsPath: 'ohio' },
  { value: 'OK', display: 'Oklahoma', stateAsPath: 'oklahoma' },
  { value: 'OR', display: 'Oregon', stateAsPath: 'oregon' },
  { value: 'PA', display: 'Pennsylvania', stateAsPath: 'pennsylvania' },
  { value: 'RI', display: 'Rhode Island', stateAsPath: 'rhode-island' },
  { value: 'SC', display: 'South Carolina', stateAsPath: 'south-carolina' },
  { value: 'SD', display: 'South Dakota', stateAsPath: 'south-dakota' },
  { value: 'TN', display: 'Tennessee', stateAsPath: 'tennessee' },
  { value: 'TX', display: 'Texas', stateAsPath: 'texas' },
  { value: 'UT', display: 'Utah', stateAsPath: 'utah' },
  { value: 'VT', display: 'Vermont', stateAsPath: 'vermont' },
  { value: 'VA', display: 'Virginia', stateAsPath: 'virginia' },
  { value: 'WA', display: 'Washington', stateAsPath: 'washington' },
  { value: 'WV', display: 'West Virginia', stateAsPath: 'west-virginia' },
  { value: 'WI', display: 'Wisconsin', stateAsPath: 'wisconsin' },
  { value: 'WY', display: 'Wyoming', stateAsPath: 'wyoming' },
];
