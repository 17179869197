import { NextSeo } from 'next-seo';
import Head from 'next/head';

export const defaultSEOConfig = {
  openGraph: {
    type: 'website',
    site_name: 'Contractorbonds.com',
  },
};

export const SEO = ({ title, description }) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
      }}
    />
  );
};

export const StateBondProductJsonLd = ({ product, url }) => {
  // Extract the amounts
  const { amounts } = product;
  // Can we create JSON-LD pages
  let offerPrice = false;
  // We have non ppk instant issue amounts --> so we can create the schema
  const instantIssueNonPPKAmounts = amounts?.filter(
    (a) => a.instantPurchase && !a.isPPK
  );

  // Default to the lowest priced one
  if (instantIssueNonPPKAmounts.length) {
    const sortedAmounts = instantIssueNonPPKAmounts.sort(
      (a, b) => a.premium - b.premium
    );
    offerPrice = sortedAmounts[0].premium;
  }

  const schema = `{
    "@context" : "http://schema.org",
    "@type" : "Product",
    "additionalType" :"http://www.productontology.org/id/Surety_bond",
    "name" : "${product.metaFields.title}",
    "description" : "${product.metaFields.description}",
    // "url" : "https://contractorbonds.com/${url}",
    "brand" : {
      "@type" : "Brand",
      "name" : "ContractorBonds.com",
      "logo" : "https://www.suretybonds.com/img/brand/surety-bonds-logo.png"
    },
    "offers" : {
      "@type" : "Offer",
      "priceCurrency" : "USD",
      "price" : "${offerPrice}",
      "itemCondition": "http://schema.org/NewCondition",
      "availability": "http://schema.org/InStock",
      "seller": {
        "@type" : "Organization",
        "name" : "ContractorBonds.com"
      }
    }
 }`;

  return (
    <>
      {offerPrice && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
          ></script>
        </Head>
      )}
    </>
  );
};
